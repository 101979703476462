/* eslint-disable for-direction */
import React, { useEffect } from 'react';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import enUS from 'rc-picker/lib/locale/en_US';
import 'rc-picker/assets/index.css';
import { startOfHour } from 'date-fns';
import { StyledRangePicker } from './RcPicker.styles';
import { StyledFormGroup } from '../../../styles/helpers.styles';
import { Error } from '../../molecules/Field/Field.styles';
import Label from '../Label';
// import Toast from '../../molecules/Toast';

function RangePicker({ label, name, onChange, value = {}, error, rules, noMargin, labelIcon, height, clear }) {
  const [_value, setValue] = React.useState([]);

  useEffect(() => {
    if (value === '') {
      setValue([]);
    } else if (
      value &&
      value.startDate &&
      value.endDate &&
      value.startDate !== _value[0] &&
      value.endDate !== _value[1]
    ) {
      setValue([new Date(value?.startDate), new Date(value?.endDate)]);
    }
  }, [value]);

  const rangePickerRef = React.useRef(null);

  const props = {
    placeholder: ['start date', 'end date'],
    allowEmpty: true,
    separator: '-',
    ref: rangePickerRef,
    locale: enUS,
    value: _value,
    generateConfig: dateFnsGenerateConfig,
    showTime: true,
    allowClear: true,
    use12Hours: true,
    showSecond: false,
    showMinute: false,
    format: 'YYYY-MM-DD hh:00 a',
    onChange: x => {
      if (!x) {
        setValue([]);
        onChange({
          target: {
            value: '',
            name,
          },
        });
      }
    },
    onOk: dates => {
      if (dates[0] && dates[1]) {
        const { isValid, message } = {
          isValid: true,
          message: '',
        };
        setValue(dates);
        onChange({
          target: {
            value: {
              startDate: startOfHour(new Date(dates[0])),
              endDate: startOfHour(new Date(dates[1])),
              message,
              isValid,
            },
            name,
          },
          message,
          isValid,
        });
      }
    },
    clearIcon: <i className="icon-close" />,
  };

  return (
    <StyledFormGroup $invalid={error} noMargin={noMargin}>
      {label && (
        <Label
          onClear={() => {
            setValue([null, null]);
            onChange({
              target: {
                value: {
                  startDate: '',
                  endDate: '',
                },
              },
            });
          }}
          required={rules?.filter(({ required }) => required).length}
          labelIcon={labelIcon}
          clear={clear}>
          {label}
        </Label>
      )}
      <StyledRangePicker $invalid={error} {...props} height={height} />
      {error && (
        <Error id={`${props.name}Error`} role="alert">
          {error}
        </Error>
      )}
    </StyledFormGroup>
  );
}
export default RangePicker;
