/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react';

import 'styled-components/macro';
import { StyledInfoCard, Title, Value } from './InfoCard.styles';

function InfoCard({ title, value, fontbase, isArrayValue, valueCss, ...props }) {
  const titleRef = useRef(null);
  const valueRef = useRef(null);

  useEffect(() => {
    const titleElement = titleRef.current;
    const valueElement = valueRef.current;

    const resizeTextToFit = element => {
      const parentWidth = element.parentElement.clientWidth;
      const elementWidth = element.clientWidth;

      if (elementWidth > parentWidth) {
        const fontSize = (parentWidth / elementWidth) * parseFloat(window.getComputedStyle(element).fontSize);
        element.style.fontSize = `${fontSize}px`;
      }
    };

    resizeTextToFit(titleElement);
    resizeTextToFit(valueElement);
  }, [title, value]);

  return (
    <StyledInfoCard {...props}>
      <Title ref={titleRef} fontbase={fontbase}>
        {title}
      </Title>
      {!isArrayValue ? (
        <Value ref={valueRef} fontbase={fontbase} css={valueCss}>
          {value}
        </Value>
      ) : (
        value?.map(val => (
          <Value ref={valueRef} fontbase={fontbase} isArrayValue key={val} css={valueCss}>
            {val}
          </Value>
        ))
      )}
    </StyledInfoCard>
  );
}

export default InfoCard;
