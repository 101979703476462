/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState } from 'react';
import { createContextHook } from 'use-context-hook';

const context = {};

export const SideNavContext = createContextHook(context);

export function SideNavContextProvider(props) {
  const [sideNavToggle, setSideNavToggle] = useState(false);

  const toggleSideNav = () => {
    setSideNavToggle(!sideNavToggle);
  };

  return (
    <SideNavContext.Provider
      value={{
        sideNavState: sideNavToggle,
        toggleSideNav,
      }}>
      {props.children}
    </SideNavContext.Provider>
  );
}
