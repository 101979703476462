import Label from 'components/atoms/Label';
import { StyledPhoneInput, InputHolder, Error } from './PhoneInput.styles';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useEffect, useState } from 'react';

const PhoneInput = ({ name, label, value, onChange, required }) => {
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (value) {
      handleInputChange(value);
    }
  }, [value]);

  const handleInputChange = number => {
    if (number) {
      setIsValid(isValidPhoneNumber(number));
      onChange({ [name]: number, valid: required ? isValidPhoneNumber(number) : !required });
    } else {
      onChange({ [name]: '', valid: required ? isValidPhoneNumber('') : !required });
    }
  };
  return (
    <InputHolder>
      <Label required={required}>{label}</Label>

      <StyledPhoneInput
        value={value}
        sm
        valid={isValid}
        placeholder="Enter phone number"
        onChange={handleInputChange}
      />
      {!isValid && <Error>Invalid Phone Number</Error>}
    </InputHolder>
  );
};
export default PhoneInput;
