import React, { Suspense } from 'react';

import Loaders from 'components/atoms/Loaders';
import { Content } from './PageTemplate.styles';
import SideNav from '../../organisms/SideNav';
import TopBar from '../../../common/TopBar';

function PageTemplate({ title, showTemplate, children, topBar }) {
  return showTemplate ? (
    <Content>
      <SideNav />
      {topBar && <TopBar title={title} />}
      <Suspense fallback={<Loaders pageLoader />}>{children}</Suspense>
    </Content>
  ) : (
    children
  );
}

export default PageTemplate;
