import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';

import apis from 'services/api';

import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import GridCol from 'components/atoms/GridCol';
import { format } from 'date-fns';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function CommentsForm({ data, onClose = () => {} }) {
  const [form] = useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({
    Comentarios: '',
  });
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
      });
      setState({
        ...data,
      });
    }
  }, [data]);
  const onSubmit = async _data => {
    try {
      setLoading(true);
      if (data) {
        await apis.updateComment(data._id, {
          ..._data,
        });
      } else {
        await apis.createComment({
          ..._data,
        });
      }
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: 'Saved successfully',
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid
        xs={12}
        lg={12}
        colGap={10}
        css={`
          align-items: center;
        `}>
        <GridCol xs={12} md={6}>
          <Form.Item
            label="Fecha"
            name="Fecha"
            placeholder="Fecha"
            prefix={<i className="material-icons-outlined">date_range</i>}
            rules={[
              {
                required: true,
              },
            ]}
            onChange={({ target: { value } }) => {
              form.setFieldsValue({
                Fecha: format(value, 'yyyy-MM-dd'),
              });
            }}>
            <Field
              type="datepicker"
              // showTimeSelect
            />
          </Form.Item>
        </GridCol>
        <GridCol xs={12} md={6}>
          <Form.Item
            type="text"
            label="Jefe de Turno"
            name="Jefe_de_Turno"
            placeholder="Jefe de Turno"
            rules={[
              {
                required: true,
              },
            ]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol xs={12} md={12}>
          <Form.Item
            sm
            label="Comentarios"
            name="Comentarios"
            placeholder="Comentarios"
            rules={[{ required: true, message: 'Please enter Comentarios' }]}
            onChange={x => {
              form.setFieldsValue({
                Comentarios: x,
              });
            }}>
            <ReactQuill theme="snow" value={state.Comentarios} />
          </Form.Item>
        </GridCol>
      </Grid>
      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        Save
      </Button>
    </Form>
  );
}

export default CommentsForm;
