import { useContextHook } from 'use-context-hook';
import { AuthContext } from 'context/authContext';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import apis from 'services/api';
import { useTranslation } from 'helpers/useTranslation';
import {
  NotificationSwitchContainer,
  NotificationSwitchLabel,
  NotificationSwitch,
  NotificationInfoText,
  NotificationTable,
  NotificationTh,
  NotificationTd,
} from '../UserActions.styles';

function Notifications() {
  const {
    lang,
    refetchUser,
    user: {
      _id: userId,
      company_id: { monitring },
      receive_notifications,
    },
  } = useContextHook(AuthContext, {
    lang: 1,
    user: 1,
    refetchUser: 1,
  });
  const machines = monitring.flatMap(({ name, shifts }) =>
    shifts
      .map(shift => ({ name, shift: shift.monitoring ? shift.name : null }))
      .filter(machine => machine.shift !== null),
  );
  const { t } = useTranslation();

  return (
    <>
      <ConfirmationModal
        title={t('Are you sure you want to change notification status ?')}
        onOk={async () => {
          await apis.updateUser(userId, { receive_notifications: !receive_notifications });
          refetchUser();
        }}
        confirmationModal="yes"
        btnComponent={({ onClick }) => (
          <NotificationSwitchContainer>
            <NotificationSwitchLabel>
              {receive_notifications
                ? t('Notifications for monitored machines are ON')
                : t('Notifications for monitored machines are OFF')}
            </NotificationSwitchLabel>
            <NotificationSwitch>
              <input type="checkbox" checked={receive_notifications} onChange={onClick} />
              <span className="slider" />
            </NotificationSwitch>
          </NotificationSwitchContainer>
        )}
      />

      <NotificationInfoText>
        {t(
          'Turn on notifications to receive updates about machines that are currently being monitored. Make sure to review the list of monitored machines and their shifts below.',
          lang.value,
        )}
      </NotificationInfoText>

      <NotificationTable>
        <thead>
          <tr>
            <NotificationTh>{t('Machine Name')}</NotificationTh>
            <NotificationTh>{t('Shift')}</NotificationTh>
          </tr>
        </thead>
        <tbody>
          {machines.map((machine, index) => (
            <tr key={index}>
              <NotificationTd>{machine.name}</NotificationTd>
              <NotificationTd>{machine.shift}</NotificationTd>
            </tr>
          ))}
        </tbody>
      </NotificationTable>
    </>
  );
}

export default Notifications;
