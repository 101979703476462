/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Toast from 'components/molecules/Toast';
import { usePermissions } from 'helpers/usePermissions';
import React, { useEffect, useState } from 'react';
import apis from 'services/api';
import styled from 'styled-components/macro';

// Styled components
const MachineCard = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
`;

const MachineName = styled.h2`
  color: #333;
  margin-bottom: 20px;
`;

const ShiftCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ShiftCard = styled.div`
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
`;

const ShiftTitle = styled.h3`
  color: #666;
  margin-bottom: 10px;
`;

const ShiftTime = styled.p`
  color: #999;
  margin-bottom: 15px;
`;

const MonitoringButton = styled.button`
  background-color: ${props => (props.monitoring ? '#4CAF50' : '#f44336')};
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
`;

const Blinker = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.color};
  display: inline-block;
  margin-left: 10px;
  animation: blinker 1s linear infinite;
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

const ScrollDiv = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: flex-start;
  padding: 20px;
  height: 70vh;
`;

function MachineList({ user }) {
  const [machines, setMachines] = useState([]);
  const { hasPermission } = usePermissions();

  const toggleMonitoring = async (machine, shift) => {
    try {
      const machineObj = machines.find(machineObj => machineObj.name === machine);
      if (machineObj) {
        const shiftObj = machineObj.shifts.find(shiftObj => shiftObj.name === shift);
        if (shiftObj) {
          shiftObj.monitoring = !shiftObj.monitoring;
          setMachines([...machines]);
          await apis.updateCompanyDetails(user.company_id._id, {
            ...user.company_id,
            monitring: machines,
            email_alert: true,
            email_details: {
              by: 'user',
              roleType: user.role_type[0],
              monitoringStatus: shiftObj.monitoring,
              machineName: machine,
              email: user.email,
              shift,
            },
          });
          Toast({
            type: 'success',
            message: 'Monitoring updated successfully (refresh to see changes on the dashboard)',
          });
        }
      }
    } catch (err) {
      Toast({
        type: 'error',
        message: err.message,
      });
    }
  };

  useEffect(() => {
    let _machines = user.company_id?.monitring ?? [];
    if (!_machines.length) {
      _machines = user.company_id?.std_units?.reduce((acc, stdUnit) => {
        const { MACHINE: machine_name } = stdUnit;
        user.company_id?.shifts_info.forEach(({ TURNO, FIN, INICIO }) => {
          const shift_name = TURNO;
          const time = `${INICIO} - ${FIN}`;
          if (!acc[machine_name]) {
            acc[machine_name] = {
              name: machine_name,
              shifts: [],
            };
          }
          acc[machine_name].shifts.push({
            name: shift_name,
            time,
            monitoring: true,
          });
        });
        return acc;
      }, {});
      apis
        .updateCompanyDetails(user.company_id._id, {
          ...user.company_id,
          monitring: Object.values(_machines),
        })
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    }
    setMachines(Object.values(_machines));
  }, []);

  const checkMonitring = (machine, shift) => {
    const machineObj = machines.find(machineObj => machineObj.name.toLowerCase() === machine.toLowerCase());
    if (machineObj) {
      const shiftObj = machineObj.shifts.find(shiftObj => shiftObj.name.toLowerCase() === shift.toLowerCase());
      if (shiftObj) {
        return shiftObj.monitoring;
      }
    }
    return false;
  };
  return (
    <ScrollDiv>
      {machines.map(machine => (
        <MachineCard key={machine.name}>
          <MachineName>{machine.name}</MachineName>
          <ShiftCardsContainer>
            {machine.shifts.map(shift => (
              <ShiftCard key={shift.name}>
                <ShiftTitle>
                  {shift.name} <Blinker color={checkMonitring(machine.name, shift.name) ? 'green' : 'red'} />
                </ShiftTitle>
                <ShiftTime>{shift.time}</ShiftTime>
                {hasPermission('profile.monitring.update') && (
                  <MonitoringButton
                    monitoring={checkMonitring(machine.name, shift.name)}
                    onClick={() => toggleMonitoring(machine.name, shift.name)}>
                    {checkMonitring(machine.name, shift.name) ? 'Disable' : 'Enable'} Monitoring
                  </MonitoringButton>
                )}
              </ShiftCard>
            ))}
          </ShiftCardsContainer>
        </MachineCard>
      ))}
    </ScrollDiv>
  );
}

export default MachineList;
