import React, { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import Select from 'components/atoms/Select';
import PhoneInput from 'components/atoms/PhoneInput';
import queryService from 'services/api';
import { useContextHook } from 'use-context-hook';

function EnquiryForm({ enquiry, onClose = () => {} }) {
  const [form] = useForm();
  const [state, setState] = useState({ valid: false });
  const [loading, setLoading] = useState(false);
  const { reFetch } = useContextHook(AuthContext, {
    reFetch: 1,
  });

  useEffect(() => {
    if (enquiry) {
      form.setFieldsValue({
        ...enquiry,
      });
      setState({
        ...enquiry,
      });
    }
  }, [enquiry]);
  const onSubmit = async data => {
    const updatedPayload = { ...data, ...state };
    const { valid, ...rest } = updatedPayload;
    try {
      setLoading(true);
      let response;
      if (enquiry) {
        response = await queryService.updateEnquiry(enquiry._id, rest);
      } else {
        response = await queryService.createEnquiry(rest);
      }
      reFetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: response.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  const handleOnChange = value => {
    setState(_ => ({ ..._, ...value }));
  };
  return (
    <Form className="from-wrap" form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="text"
          label="Name"
          name="name"
          placeholder="Equirer's name"
          rules={[
            {
              pattern: /^[a-zA-Z_ ]+$/,
              message: 'Only alphabets and _ are allowed',
            },
          ]}>
          <Field />
        </Form.Item>

        <Form.Item
          sm
          type="email"
          label="Email"
          name="email"
          placeholder="Email"
          rules={[
            { required: true, message: 'Please enter email address' },
            { email: true, message: 'Please enter a valid email' },
            { max: 40, message: 'Email should be at max 40 characters!' },
          ]}>
          <Field />
        </Form.Item>

        <PhoneInput
          value={state?.phone_number}
          required
          name="phone_number"
          label="Phone Number"
          onChange={handleOnChange}
        />

        <Form.Item
          sm
          type="text"
          label="Company Name"
          name="company"
          placeholder="Company Name"
          rules={[
            {
              pattern: /^[a-zA-Z_ ]+$/,
              message: 'Only alphabets and _ are allowed',
            },
          ]}>
          <Field />
        </Form.Item>

        <div />
      </Grid>

      <Form.Item
        type="text"
        placeholder="Source"
        label="Source"
        name="source"
        // rules={[
        //   {
        //     pattern: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9#?&=_.-]+)*$/,
        //     message: 'Please enter a valid url',
        //   },
        // ]}
      >
        <Field />
      </Form.Item>

      {/* Multipe Fields Component */}

      {/* <Form.Item
        type="text"
        placeholder="Source"
        label="Data"
        name="source"
      >
        <MultipleField />
      </Form.Item> */}

      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}></Grid>
      <Form.Item type="textarea" label="Important Details" name="info" placeholder="Share Important Details">
        <Field />
      </Form.Item>
      <Button
        disabled={!state?.valid}
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        Save
      </Button>
    </Form>
  );
}

export default EnquiryForm;
