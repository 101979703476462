import { toast } from 'react-toastify';
import AlertIcon from 'components/atoms/AlertIcon';
import React from 'react';
import { StyledAlert, Message } from './Toast.styles';

function Toast({ type, message, autoClose, ...props }) {
  return toast(
    <StyledAlert $type={type} {...props}>
      <AlertIcon $type={type} />
      <Message $type={type}>
        {message.includes('\n')
          ? message.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))
          : message}
      </Message>
    </StyledAlert>,
    {
      position: toast.POSITION.BOTTOM_CENTER,
      hideProgressBar: true,
      autoClose: autoClose ?? 5000,
    },
  );
}

export default Toast;
