import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';
import { LoadingContext } from 'context/loadingContext';
import { useContextHook } from 'use-context-hook';
import { SideNavContext } from 'context/sideNavContext';
import { AuthContext } from 'context/authContext';
import ModalContainer from 'components/molecules/ModalContainer';
import Avatar from 'assets/images/userred.png';
import { useTranslation } from 'helpers/useTranslation';
import {
  ProfileHolder,
  UserWrap,
  ImgBox,
  TextBox,
  DropDown,
  Ul,
  Li,
  StyledLink,
  Name,
  Text,
  IconHolder,
} from './UserActions.styles';
import Settings from './Settings';

function UserActions() {
  const { toggleSideNav } = useContextHook(SideNavContext, {
    toggleSideNav: 1,
  });

  const { onLogout, user } = useContextHook(AuthContext, {
    onLogout: 1,
    user: 1,
  });

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const { isLoading } = useContextHook(LoadingContext, {
    isLoading: 1,
  });

  return isLoading ? (
    <Skeleton circle height={40} width={40} />
  ) : (
    <ProfileHolder>
      <UserWrap onClick={() => setIsOpen(!isOpen)}>
        <ImgBox>
          <img src={Avatar} width="40" height="40" alt="user" />
        </ImgBox>

        <TextBox>
          <Name>{user?.ipAddress ?? t('Actions')}</Name>
        </TextBox>
        <i className="icon-chevron-down material-icons-outlined">expand_more</i>
      </UserWrap>

      <DropDown dropdownOpen={isOpen}>
        <Ul>
          <ModalContainer
            btnComponent={({ onClick }) => (
              <Li index="1">
                <StyledLink as="span" css="cursor: pointer;" onClick={onClick}>
                  <IconHolder>
                    <i className="material-icons-outlined">settings</i>
                  </IconHolder>
                  <Text>{t('settings')}</Text>
                </StyledLink>
              </Li>
            )}
            content={() => <Settings />}
            width={1600}
            title={t('Profile Settings')}
          />

          <Li index="2">
            <StyledLink
              as="span"
              css="cursor: pointer;"
              onClick={e => {
                e.preventDefault();
                toggleSideNav();
                onLogout();
              }}>
              <IconHolder>
                <i className="material-icons-outlined">logout</i>
              </IconHolder>
              <Text>{t('Logout')}</Text>
            </StyledLink>
          </Li>
        </Ul>
      </DropDown>
    </ProfileHolder>
  );
}

export default UserActions;
