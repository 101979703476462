import Loaders from 'components/atoms/Loaders';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import React, { useState } from 'react';
import apis from 'services/api';
import styled from 'styled-components/macro';
import { useContextHook } from 'use-context-hook';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 20px;
  h3 {
    margin: 0;
  }
  input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
  }
  button {
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #000;
  }
`;
const defaults = {
  logistica_chart: { default: ['Cuadros Despachados'], current: [] },
  embalaje_chart_1: { default: ['Sector de Embalaje Cuadros Finalizados'], current: [] },
  embalaje_chart_2: { default: ['Sector Armado Cuadros de Marcos Corredizos Finalizados'], current: [] },
  TotalPiezasChart: { default: ['Cuadros', 'Cuadros STD'], current: [] },
  utilizationChart: { default: ['Utilizacion Disponible Real'], current: [] },
  timeCycleChart: { default: ['Tiempo de Ciclo [seg]', 'Tiempo de Ciclo STD [seg]'], current: [] },
  stoppagesChart: { default: ['Paradas [hs]'], current: [] },
  especiales_chart: { default: ['Mosquiteros', 'Cuadros grandes'], current: [] },
};
export default function ReportsSettings({ onClose }) {
  const {
    user: {
      company_id: { chartOptions = {}, _id },
    },
    refetchUser,
  } = useContextHook(AuthContext, { user: 1, refetchUser: 1 });
  const defaultOptions = Object.keys(chartOptions).length
    ? chartOptions
    : Object.keys(defaults).reduce((acc, key) => {
        acc[key] = { default: defaults[key].default, current: defaults[key].default };
        return acc;
      }, {});
  const [state, setState] = useState(defaultOptions);
  const [loading, setLoading] = useState(false);
  const reset = async () => {
    let __state = Object.keys(defaults).reduce((acc, key) => {
      acc[key] = { default: defaults[key].default, current: defaults[key].default };
      return acc;
    }, {});
    setState(__state);
    await save(__state);
  };
  const save = async (data = null) => {
    try {
      setLoading(true);
      await apis.updateCompanyDetails(_id, {
        chartOptions: data ? data : state,
        isChartOptions: true,
      });
      Toast({
        type: 'success',
        message: `Company data updated successfully`,
      });
      setLoading(false);
      onClose();
      refetchUser();
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  return (
    <Loaders loading={loading}>
      <Wrapper>
        {Object.entries(defaultOptions).map(([key, value]) => (
          <div key={key}>
            <h3>{key}</h3>
            <div>
              {value.current.map((item, i) => (
                <input
                  key={item}
                  value={state[key].current[i]}
                  onChange={e => {
                    const newValue = e.target.value;
                    setState(prev => ({
                      ...prev,
                      [key]: {
                        ...prev[key],
                        current: prev[key].current.map((v, idx) => (idx === i ? newValue : v)),
                      },
                    }));
                  }}
                />
              ))}
            </div>
          </div>
        ))}
        <button onClick={() => reset()}>Reset</button>
        <button onClick={() => save()}>Save</button>
      </Wrapper>
    </Loaders>
  );
}
