import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import GridCol from 'components/atoms/GridCol';
import Select from 'components/atoms/Select';
import Field from 'components/molecules/Field';
import { useForm } from 'components/molecules/Form';
import Form from 'components/molecules/Form/Form';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import React, { useMemo } from 'react';
import apis from 'services/api';
import { useContextHook } from 'use-context-hook';

export default function CompanyForm({ onClose }) {
  const [form] = useForm();
  const { reFetch } = useContextHook(AuthContext, {
    reFetch: 1,
  });
  const { roles_data } = apis.GetRoles({ getAll: true });
  const roles = useMemo(() => roles_data.roles.map(({ _id, type }) => ({ value: _id, label: type })), [roles_data]);
  const [loading, setLoading] = React.useState(false);
  const onRegister = async _ => {
    setLoading(true);
    apis
      .register({ ..._, roles: _?.roles.map(({ value }) => value) })
      .then(() => {
        onClose();
        reFetch();
        setLoading(false);
        Toast({
          type: 'success',
          message: 'Company Registered Successfully',
        });
      })
      .catch(({ message }) => {
        setLoading(false);
        Toast({
          message,
          type: 'error',
        });
      });
  };
  return (
    <Form
      form={form}
      onSubmit={_ =>
        onRegister(_, () => {
          reFetch();
          onClose();
        })
      }>
      <Grid lg={24} xl={25}>
        <GridCol lg={24} xl={24}>
          <Form.Item
            type="text"
            label="Email"
            name="email"
            placeholder="Your Email"
            prefix={<i className="material-icons-outlined">email</i>}
            rules={[{ required: true }]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol lg={12} xl={12}>
          <Form.Item
            type="text"
            label="First Name"
            name="first_name"
            placeholder="Your first name"
            prefix={<i className="material-icons-outlined">person</i>}
            rules={[{ required: true }]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol lg={12} xl={12}>
          <Form.Item
            type="text"
            label="Last Name"
            name="last_name"
            placeholder="Your last name"
            prefix={<i className="material-icons-outlined">person</i>}
            rules={[{ required: true }]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol lg={24} xl={24}>
          <Form.Item
            sm
            options={roles}
            isSearchable
            isMulti
            name="roles"
            label="Role"
            placeholder="Role"
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            rules={[
              { required: true, message: 'Select atleast one role' },
              {
                transform: value => !value?.length,
                message: 'Select at least one role',
              },
            ]}>
            <Select />
          </Form.Item>
        </GridCol>
        <GridCol lg={24} xl={24}>
          <Form.Item
            type="text"
            label="Company Name"
            name="company_name"
            placeholder="Your company name"
            prefix={<i className="material-icons-outlined">add_business</i>}
            rules={[{ required: true }]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol lg={24} xl={24}>
          <Form.Item
            type="number"
            label="Phone Number"
            name="phone_number"
            placeholder="Your phone number"
            prefix={<i className="material-icons-outlined">phone</i>}
            rules={[{ required: true }]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol lg={24} xl={24}>
          <Form.Item
            type="password"
            label="Password"
            name="password"
            placeholder="Your Password"
            prefix={<i className="material-icons-outlined">lock</i>}
            rules={[
              {
                required: true,
              },
              {
                password: true,
              },
            ]}>
            <Field />
          </Form.Item>
        </GridCol>
      </Grid>
      <Button loading={loading} type="primary" htmlType="submit" width={150} css="margin: 0 auto;">
        Register
      </Button>
    </Form>
  );
}
