import React, { useEffect, useState } from 'react';
import 'styled-components/macro';

import Heading from 'components/atoms/Heading';
import Button from 'components/atoms/Button';
import Modal from 'components/molecules/Modal';
import { BtnHolder, Subtitle } from './ConfirmationModal.styles';

function ConfirmationModal({
  cancelText = 'Cancel',
  btnComponent,
  title,
  subtitle,
  deleteModal,
  confirmationModal,
  children,
  okLoading,
  onModalClose = () => {},
  onOk = () => {},
  stateChanged = () => {},
}) {
  const [isVisible, setIsVisible] = useState(false);
  const [p_isVisible, p_setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const showModal = () => {
    stateChanged({ [title]: true });
    setIsVisible(true);
    p_setIsVisible(true);
  };
  const handleCancel = () => {
    stateChanged({ [title]: false });
    setIsVisible(false);
  };

  useEffect(() => {
    if (p_isVisible && !isVisible) {
      onModalClose();
    }
  }, [isVisible]);

  return (
    <>
      {btnComponent && btnComponent({ onClick: showModal })}
      <Modal isOpen={isVisible} setIsOpen={setIsVisible}>
        <>
          <Heading level={3} css="margin-bottom: 10px; font-weight: 500;">
            {title}
          </Heading>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {children ?? null}
          <BtnHolder>
            <Button type="outline" width={130} onClick={handleCancel}>
              {cancelText}
            </Button>
            {deleteModal && (
              <Button
                type="danger"
                width={130}
                loading={okLoading || loading}
                onClick={async () => {
                  setLoading(true);
                  await onOk();
                  setLoading(false);
                  handleCancel();
                }}>
                {deleteModal.length === undefined ? 'Yes' : deleteModal}
              </Button>
            )}
            {confirmationModal && (
              <Button
                type="primary"
                width={130}
                onClick={async () => {
                  setLoading(true);
                  await onOk();
                  setLoading(false);
                  handleCancel();
                }}
                loading={okLoading || loading}>
                {confirmationModal}
              </Button>
            )}
          </BtnHolder>
        </>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
