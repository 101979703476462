/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import TableLayout from 'components/atoms/TableLayout';
import { Wrapper } from 'styles/App.styles';
import Table from 'components/molecules/Table';
import { ActionBtnHolder } from 'styles/helpers.styles';
import ModalContainer from 'components/molecules/ModalContainer';
import Button from 'components/atoms/Button';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import { usePermissions } from 'helpers/usePermissions';
import 'styled-components/macro';
import Toast from 'components/molecules/Toast';
import UploadCsv from 'components/organisms/CsvUpload';
import Form, { useForm } from 'components/molecules/Form';
import { useContextHook } from 'use-context-hook';
import { AuthContext } from 'context/authContext';
import apis from 'services/api';
import Alert from 'components/molecules/Alert';
import Field from 'components/molecules/Field';

function AddSTDForm({ onClose }) {
  const [form] = useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    refetchUser,
    user: { company_id: company },
  } = useContextHook(AuthContext, {
    refetchUser: 1,
    user: 1,
  });
  useEffect(() => {
    if (company) {
      form.setFieldsValue({
        ...company,
      });
      setState({
        ...company,
      });
    }
  }, [company]);
  const onSubmit = async () => {
    try {
      setLoading(true);
      await apis.updateCompanyDetails(state._id, state);
      Toast({
        type: 'success',
        message: `Company data updated successfully`,
      });
      setLoading(false);
      onClose();
      refetchUser();
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  return (
    <>
      <Alert
        type="info"
        css={`
          margin-bottom: 20px;
        `}
        message={
          <small>
            Please upload csv std units file with the following columns in same sequence: EMPRESA, MACHINE, STD CYCLE
            RATIO, IOT, CONNECTOR ,UPSTD, UDSTD, MICROSTD, PZSSTD, DIVIDED_BY.
          </small>
        }
      />
      <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
        <Form.Item
          type="text"
          label="Select std units csv"
          name="std_units"
          prefix={<i className="material-icons-outlined">person</i>}
          rules={[{ required: true }]}>
          <UploadCsv
            onClose={e => {
              if (typeof e === 'string')
                Toast({
                  type: 'error',
                  message: e,
                });
              form.setFieldsValue({
                std_units: '',
              });
            }}
            keys={[
              'EMPRESA',
              'MACHINE',
              'STD CYCLE RATIO',
              'IOT',
              'CONNECTOR',
              'UPSTD',
              'UDSTD',
              'MICROSTD',
              'PZSSTD',
              'DIVIDED_BY',
            ]}
            onSubmit={e => {
              if (e?.length) {
                form.setFieldsValue({
                  std_units: e,
                });
              } else {
                form.setFieldsValue({
                  std_units: '',
                });
              }
            }}
          />
        </Form.Item>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          css={`
            margin-top: 20px;
          `}>
          Save
        </Button>
      </Form>
    </>
  );
}

function UpdateSTDForm({ std, onClose }) {
  const [form] = useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    refetchUser,
    user: { company_id: company },
  } = useContextHook(AuthContext, {
    refetchUser: 1,
    user: 1,
  });
  useEffect(() => {
    if (company) {
      form.setFieldsValue({
        ...std,
      });
      setState({
        ...std,
      });
    }
  }, [company]);
  const onSubmit = async () => {
    try {
      setLoading(true);
      await apis.updateCompanyDetails(company._id, {
        ...company,
        std_units: company.std_units.map(_ => (_.MACHINE === std.MACHINE ? state : _)),
      });
      Toast({
        type: 'success',
        message: `Company data updated successfully`,
      });
      setLoading(false);
      onClose();
      refetchUser();
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Form.Item
        type="text"
        label="EMPRESA"
        name="EMPRESA"
        disabled
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="MACHINE"
        name="MACHINE"
        disabled
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="STD CYCLE RATIO"
        name="STD CYCLE RATIO"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="IOT"
        name="IOT"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="CONNECTOR"
        name="CONNECTOR"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="UP STD"
        name="UPSTD"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="UD STD"
        name="UDSTD"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="MICRO STD"
        name="MICROSTD"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="DIVIDED_BY"
        name="DIVIDED_BY"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>

      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        Save
      </Button>
    </Form>
  );
}

function AddSTDSingle({ std, onClose }) {
  const [form] = useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    refetchUser,
    user: { company_id: company },
  } = useContextHook(AuthContext, {
    refetchUser: 1,
    user: 1,
  });
  useEffect(() => {
    if (company) {
      form.setFieldsValue({
        EMPRESA: company.company_name.toUpperCase(),
      });
      setState({
        EMPRESA: company.company_name.toUpperCase(),
      });
    }
  }, [company]);
  const onSubmit = async () => {
    try {
      setLoading(true);
      await apis.updateCompanyDetails(company._id, {
        ...company,
        std_units: [...company.std_units, state],
      });
      Toast({
        type: 'success',
        message: `Company data updated successfully`,
      });
      setLoading(false);
      onClose();
      refetchUser();
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Form.Item
        type="text"
        label="EMPRESA"
        name="EMPRESA"
        disabled
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="MACHINE"
        name="MACHINE"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="STD CYCLE RATIO"
        name="STD CYCLE RATIO"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="IOT"
        name="IOT"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="CONNECTOR"
        name="CONNECTOR"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="UP STD"
        name="UPSTD"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="UD STD"
        name="UDSTD"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="MICRO STD"
        name="MICROSTD"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="PZS STD"
        name="PZSSTD"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="DIVIDED_BY"
        name="DIVIDED_BY"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>

      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        Save
      </Button>
    </Form>
  );
}
export default function MachinesAndStdUnits({ user }) {
  const { hasPermission } = usePermissions();
  const {
    refetchUser,
    user: { company_id: company },
  } = useContextHook(AuthContext, {
    refetchUser: 1,
    user: 1,
  });
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 10,
    searchText: '',
    totalCount: user?.company_id?.std_units?.length ?? 0,
  });

  const [rows, setRows] = useState([]);

  const paginate = ({ rows: _rows, page, pageSize, searchText }) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const filteredRows = _rows.filter(_ => {
      const regex = new RegExp(searchText, 'i');
      return regex.test(_.MACHINE);
    });
    setSearchQuery({ ...searchQuery, totalCount: filteredRows.length });
    return filteredRows.slice(startIndex, endIndex);
  };

  useEffect(() => {
    setRows(
      paginate({
        rows: user?.company_id?.std_units ?? [],
        ...searchQuery,
      }),
    );
  }, [searchQuery.page, searchQuery.pageSize, searchQuery.searchText]);

  const deleteRecord = async _ => {
    try {
      await apis.updateCompanyDetails(company._id, {
        ...company,
        std_units: company.std_units.filter(__ => __.MACHINE !== _.MACHINE),
      });
      Toast({
        type: 'success',
        message: `Company data updated successfully`,
      });
      refetchUser();
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  const actionBtns = _ => (
    <ActionBtnHolder>
      {hasPermission('profile.std.edit') && (
        <ModalContainer
          lg
          title="Edit"
          btnComponent={({ onClick }) => (
            <Button unStyled className="edit-btn" onClick={onClick}>
              <i className="material-icons-outlined">edit</i>
            </Button>
          )}
          content={({ onClose }) => <UpdateSTDForm onClose={onClose} std={_} />}
        />
      )}
      {hasPermission('profile.std.delete') && (
        <ConfirmationModal
          title="Are you sure you want to delete this record?"
          subtitle={"you can't undo this action"}
          deleteModal
          onOk={() => deleteRecord(_)}
          btnComponent={({ onClick }) => (
            <Button unStyled size={20} className="delete-btn" onClick={onClick}>
              <i className="material-icons-outlined">delete_forever</i>
            </Button>
          )}
        />
      )}
    </ActionBtnHolder>
  );

  const rowsData = useMemo(
    () =>
      rows.map(_ => [
        _.EMPRESA,
        _.MACHINE,
        _['STD CYCLE RATIO'],
        _.IOT,
        _.CONNECTOR,
        _.UPSTD,
        _.UDSTD,
        _.MICROSTD,
        _.PZSSTD,
        _.DIVIDED_BY,
        actionBtns(_),
      ]),
    [rows],
  );
  const columns = [
    'EMPRESA',
    'MACHINE',
    'STD CYCLE RATIO',
    'IOT',
    'CONNECTOR',
    'UPSTD',
    'UDSTD',
    'MICROSTD',
    'PZS STD',
    'DIVIDED_BY',
    '',
  ];

  return (
    <Wrapper>
      <TableLayout
        customFilterKey="text-only"
        onChangeFilters={filters => {
          setSearchQuery(_ => ({
            ..._,
            ...filters,
          }));
        }}
        deleteAll={
          hasPermission('profile.std.upload') && (
            <>
              <ModalContainer
                title="Upload STD units csv"
                btnComponent={({ onClick }) => (
                  <Button
                    unStyled
                    css={`
                      color: green;
                      font-size: 20px;
                    `}
                    onClick={onClick}>
                    <i className="material-icons-outlined">upload_file</i>
                  </Button>
                )}
                content={({ onClose }) => <AddSTDForm onClose={onClose} />}
              />
              <ModalContainer
                lg
                title="Add STD unit"
                btnComponent={({ onClick }) => (
                  <Button
                    unStyled
                    className="edit-btn"
                    onClick={onClick}
                    css={`
                      color: green;
                      font-size: 20px;
                    `}>
                    <i className="material-icons-outlined">add_circle</i>
                  </Button>
                )}
                content={({ onClose }) => <AddSTDSingle onClose={onClose} />}
              />
            </>
          )
        }
        currentPage={searchQuery.page}
        totalCount={searchQuery.totalCount}
        pageSize={searchQuery.pageSize}>
        <Table loading={false} rowsData={rowsData} columnNames={columns} noPadding />
      </TableLayout>
    </Wrapper>
  );
}
