/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import TableLayout from 'components/atoms/TableLayout';
import { Wrapper } from 'styles/App.styles';
import Table from 'components/molecules/Table';
import { ActionBtnHolder } from 'styles/helpers.styles';
import ModalContainer from 'components/molecules/ModalContainer';
import Button from 'components/atoms/Button';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import { usePermissions } from 'helpers/usePermissions';
import 'styled-components/macro';
import Toast from 'components/molecules/Toast';
import UploadCsv from 'components/organisms/CsvUpload';
import Form, { useForm } from 'components/molecules/Form';
import { useContextHook } from 'use-context-hook';
import { AuthContext } from 'context/authContext';
import apis from 'services/api';
import Alert from 'components/molecules/Alert';
import Field from 'components/molecules/Field';

function AddShiftForm({ onClose }) {
  const [form] = useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    refetchUser,
    user: { company_id: company },
  } = useContextHook(AuthContext, {
    refetchUser: 1,
    user: 1,
  });
  useEffect(() => {
    if (company) {
      form.setFieldsValue({
        ...company,
      });
      setState({
        ...company,
      });
    }
  }, [company]);
  const onSubmit = async () => {
    try {
      setLoading(true);
      await apis.updateCompanyDetails(state._id, state);
      Toast({
        type: 'success',
        message: `Company data updated successfully`,
      });
      setLoading(false);
      onClose();
      refetchUser();
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  return (
    <>
      <Alert
        type="info"
        css={`
          margin-bottom: 20px;
        `}
        message={
          <small>
            Please upload csv file with the following columns in same sequence: EMPRESA, TURNO, INICIO, FIN .<br />
            All timings should be in HH:MM (Argentina Timezone) format.
          </small>
        }
      />
      <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
        <Form.Item
          type="text"
          label="Select TURNO csv"
          name="shifts_info"
          prefix={<i className="material-icons-outlined">person</i>}
          rules={[{ required: true }]}>
          <UploadCsv
            onClose={e => {
              if (typeof e === 'string')
                Toast({
                  type: 'error',
                  message: e,
                });
              form.setFieldsValue({
                shifts_info: '',
              });
            }}
            keys={['EMPRESA', 'TURNO', 'INICIO', 'FIN']}
            onSubmit={e => {
              if (e?.length) {
                form.setFieldsValue({
                  shifts_info: e,
                });
              } else {
                form.setFieldsValue({
                  shifts_info: '',
                });
              }
            }}
          />
        </Form.Item>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          css={`
            margin-top: 20px;
          `}>
          Save
        </Button>
      </Form>
    </>
  );
}

function UpdateShiftForm({ shift, onClose }) {
  const [form] = useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    refetchUser,
    user: { company_id: company },
  } = useContextHook(AuthContext, {
    refetchUser: 1,
    user: 1,
  });
  useEffect(() => {
    if (company) {
      form.setFieldsValue({
        ...shift,
      });
      setState({
        ...shift,
      });
    }
  }, [company]);
  const onSubmit = async () => {
    try {
      setLoading(true);
      await apis.updateCompanyDetails(company._id, {
        ...company,
        shifts_info: company.shifts_info.map(_ => (_.TURNO === shift.TURNO ? state : _)),
      });
      Toast({
        type: 'success',
        message: `Company data updated successfully`,
      });
      setLoading(false);
      onClose();
      refetchUser();
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  return (
    <>
      <Alert
        type="info"
        css={`
          margin-bottom: 20px;
        `}
        message={<small>All timings should be in HH:MM (Argentina Timezone) format.</small>}
      />
      <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
        <Form.Item
          type="text"
          label="EMPRESA"
          name="EMPRESA"
          disabled
          prefix={<i className="material-icons-outlined">person</i>}
          rules={[{ required: true }]}>
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          label="TURNO"
          name="TURNO"
          disabled
          prefix={<i className="material-icons-outlined">person</i>}
          rules={[{ required: true }]}>
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          label="INICIO"
          name="INICIO"
          prefix={<i className="material-icons-outlined">person</i>}
          rules={[{ required: true }]}>
          <Field />
        </Form.Item>
        <Form.Item
          type="text"
          label="FIN"
          name="FIN"
          prefix={<i className="material-icons-outlined">person</i>}
          rules={[{ required: true }]}>
          <Field />
        </Form.Item>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          css={`
            margin-top: 20px;
          `}>
          Save
        </Button>
      </Form>
    </>
  );
}

export default function ShiftsAndTimings({ user }) {
  const { hasPermission } = usePermissions();
  const {
    refetchUser,
    user: { company_id: company },
  } = useContextHook(AuthContext, {
    refetchUser: 1,
    user: 1,
  });
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 10,
    searchText: '',
    totalCount: user?.company_id?.shifts_info?.length,
  });

  const [rows, setRows] = useState([]);

  const paginate = ({ rows: _rows, page, pageSize, searchText }) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const filteredRows = _rows.filter(_ => {
      const regex = new RegExp(searchText, 'i');
      return regex.test(_.TURNO);
    });
    setSearchQuery({ ...searchQuery, totalCount: filteredRows.length });
    return filteredRows.slice(startIndex, endIndex);
  };

  useEffect(() => {
    setRows(
      paginate({
        rows: user?.company_id?.shifts_info,
        ...searchQuery,
      }),
    );
  }, [searchQuery.page, searchQuery.pageSize, searchQuery.searchText]);

  const deleteRecord = async _ => {
    try {
      await apis.updateCompanyDetails(company._id, {
        ...company,
        shifts_info: company.shifts_info.filter(__ => __.TURNO !== _.TURNO),
      });
      Toast({
        type: 'success',
        message: `Company data updated successfully`,
      });
      refetchUser();
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  const actionBtns = _ => (
    <ActionBtnHolder>
      {hasPermission('profile.shift_timings.edit') && (
        <ModalContainer
          lg
          title="Edit"
          btnComponent={({ onClick }) => (
            <Button unStyled className="edit-btn" onClick={onClick}>
              <i className="material-icons-outlined">edit</i>
            </Button>
          )}
          content={({ onClose }) => <UpdateShiftForm onClose={onClose} shift={_} />}
        />
      )}
      {hasPermission('profile.shift_timings.delete') && (
        <ConfirmationModal
          title="Are you sure you want to delete this record?"
          subtitle={"you can't undo this action"}
          deleteModal
          onOk={() => deleteRecord(_)}
          btnComponent={({ onClick }) => (
            <Button unStyled size={20} className="delete-btn" onClick={onClick}>
              <i className="material-icons-outlined">delete_forever</i>
            </Button>
          )}
        />
      )}
    </ActionBtnHolder>
  );

  const rowsData = useMemo(() => rows.map(_ => [_.TURNO, _.INICIO, _.FIN, actionBtns(_)]), [rows]);

  const columns = ['TURNO', 'INICIO', 'FIN', ''];

  return (
    <Wrapper>
      <TableLayout
        customFilterKey="text-only"
        onChangeFilters={filters => {
          setSearchQuery(_ => ({
            ..._,
            ...filters,
          }));
        }}
        deleteAll={
          hasPermission('profile.shift_timings.upload') && (
            <ModalContainer
              title="Upload shifts csv"
              btnComponent={({ onClick }) => (
                <Button
                  unStyled
                  css={`
                    color: green;
                    font-size: 20px;
                  `}
                  onClick={onClick}>
                  <i className="material-icons-outlined">add_circle</i>
                </Button>
              )}
              content={({ onClose }) => <AddShiftForm onClose={onClose} />}
            />
          )
        }
        currentPage={searchQuery.page}
        totalCount={searchQuery.totalCount}
        pageSize={searchQuery.pageSize}>
        <Table loading={false} rowsData={rowsData} columnNames={columns} noPadding />
      </TableLayout>
    </Wrapper>
  );
}
