/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import GridCol from 'components/atoms/GridCol';
import { useContextHook } from 'use-context-hook';
import apis from 'services/api';
import UploadCsv from '../CsvUpload';

function UpdateCompanyInfo({ onClose = () => {} }) {
  const [form] = useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    reFetch,
    user: { company_id: company },
  } = useContextHook(AuthContext, {
    reFetch: 1,
    user: 1,
  });

  useEffect(() => {
    if (company) {
      form.setFieldsValue({
        ...company,
      });
      setState({
        ...company,
      });
    }
  }, [company]);

  const onSubmit = async data => {
    try {
      setLoading(true);
      await apis.updateCompanyDetails(state._id, state);
      Toast({
        type: 'success',
        message: `Company data updated successfully`,
      });
      setLoading(false);
      onClose();
      reFetch();
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid lg={24} xl={25} gap={20}>
        <GridCol lg={24} xl={24}>
          <Form.Item
            type="text"
            label="Company Name"
            name="company_name"
            placeholder="Your Company name"
            prefix={<i className="material-icons-outlined">person</i>}
            rules={[{ required: true }]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol lg={12} xl={12}>
          <Form.Item
            type="text"
            label="Select TURNO csv"
            name="shifts_info"
            prefix={<i className="material-icons-outlined">person</i>}
            rules={[{ required: true }]}>
            <UploadCsv
              onClose={e => {
                if (typeof e === 'string')
                  Toast({
                    type: 'error',
                    message: e,
                  });
                form.setFieldsValue({
                  shifts_info: '',
                });
              }}
              keys={['EMPRESA', 'TURNO', 'INICIO', 'FIN']}
              onSubmit={e => {
                if (e?.length) {
                  form.setFieldsValue({
                    shifts_info: e,
                  });
                } else {
                  form.setFieldsValue({
                    shifts_info: '',
                  });
                }
              }}
            />
          </Form.Item>
        </GridCol>
        <GridCol lg={12} xl={12}>
          <Form.Item
            type="text"
            label="Select Reasons Csv"
            name="stoppage_reasons"
            prefix={<i className="material-icons-outlined">person</i>}
            rules={[{ required: true }]}>
            <UploadCsv
              onClose={e => {
                if (typeof e === 'string')
                  Toast({
                    type: 'error',
                    message: e,
                  });
                form.setFieldsValue({
                  stoppage_reasons: '',
                });
              }}
              keys={['EMPRESA', 'CAUSA', 'DESCRIPCION', 'Tipo de TIEMPO - AGO', 'Tipo de PARADA']}
              onSubmit={e => {
                if (e?.length) {
                  form.setFieldsValue({
                    stoppage_reasons: e,
                  });
                } else {
                  form.setFieldsValue({
                    stoppage_reasons: '',
                  });
                }
              }}
            />
          </Form.Item>
        </GridCol>
      </Grid>

      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        Save
      </Button>
    </Form>
  );
}

export default UpdateCompanyInfo;
