/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import { AuthContext } from 'context/authContext';

import Heading from 'components/atoms/Heading';
import Logo from 'components/atoms/Logo';

import Button from 'components/atoms/Button';
import { useContextHook } from 'use-context-hook';
import Grid from 'components/atoms/Grid';

import GridCol from 'components/atoms/GridCol';
import { useTranslation } from 'helpers/useTranslation';
import Select from 'components/atoms/Select';
import LoginTemplate from '../../templates/LoginTemplate';
import Field from '../../molecules/Field';
import Form, { useForm } from '../../molecules/Form';

import { SubTitle, LoginHead, FormHolder, StyledForm } from './Login.styles';
import ModalContainer from 'components/molecules/ModalContainer';
import Toast from 'components/molecules/Toast';

function Login() {
  const [form] = useForm();
  const [forgetPassword, setForgetPassword] = useState(false);
  const { lang, setLang } = useContextHook(AuthContext, {
    lang: 1,
    setLang: 1,
  });
  const { onLogin, onResetPassword, user_loading } = useContextHook(AuthContext, {
    onLogin: 1,
    user_loading: 1,
    onResetPassword: 1,
  });
  const handleChange = e => {
    setLang(e.target.value);
  };
  const { t } = useTranslation();
  return (
    <LoginTemplate>
      <LoginHead>
        <Logo />
        <Select
          css={`
            width: 150px;
          `}
          label={t('Select Language')}
          options={[
            { value: 'en', label: 'English' },
            { value: 'sp', label: 'Español' },
          ]}
          value={lang}
          sm
          onChange={handleChange}
        />
      </LoginHead>

      {!forgetPassword && (
        <FormHolder>
          <Heading level={1}>{t('Sign in')}</Heading>
          <SubTitle>
            {t('Click below to try the demo')}
            <ModalContainer
              title="Demo"
              btnComponent={({ onClick }) => (
                <a href="#" type="link" onClick={onClick}>
                  <Button loading={user_loading} type="primary" htmlType="submit" width={150} css="margin: 0 auto;">
                    DEMO
                  </Button>
                </a>
              )}
              content={() => (
                <>
                  <SubTitle>
                    {t('Enter your email to try the demo')} <br />
                  </SubTitle>
                  <StyledForm form={form} onSubmit={onLogin}>
                    <Form.Item
                      type="text"
                      label={t('Email')}
                      name="email"
                      placeholder={t('Your Email')}
                      prefix={<i className="material-icons-outlined">email</i>}
                      rules={[{ required: true }]}>
                      <Field />
                    </Form.Item>
                    <Button
                      loading={user_loading}
                      type="primary"
                      onClick={() => {
                        let email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                        if (form.getFieldValue('email') === '' || !email_regex.test(form.getFieldValue('email'))) {
                          Toast({
                            type: 'error',
                            message: 'Please enter a valid email',
                          });
                        } else {
                          onLogin({
                            email: 'demo@demo.app',
                            password: 'Demo@user1',
                            demo_user_mail: form.getFieldValue('email'),
                          });
                        }
                      }}
                      width={150}
                      css="margin: 0 auto;">
                      {t('Sign in')}
                    </Button>{' '}
                    <br />
                  </StyledForm>
                </>
              )}
            />
          </SubTitle>

          <StyledForm form={form} onSubmit={onLogin}>
            <Form.Item
              type="text"
              label={t('Email')}
              name="email"
              placeholder={t('Your Email')}
              prefix={<i className="material-icons-outlined">email</i>}
              rules={[{ required: true }]}>
              <Field />
            </Form.Item>
            <Form.Item
              type="password"
              label={t('Password')}
              name="password"
              placeholder={t('Your Password')}
              prefix={<i className="material-icons-outlined">lock</i>}
              rules={[
                {
                  required: true,
                },
              ]}>
              <Field />
            </Form.Item>
            <p
              style={{
                float: 'right',
              }}>
              <small>
                <a
                  href="#"
                  type="link"
                  onClick={e => {
                    e.preventDefault();
                    setForgetPassword(true);
                  }}>
                  {t('Forgot password')}?
                </a>
              </small>
            </p>
            <br />
            <Button loading={user_loading} type="primary" htmlType="submit" width={150} css="margin: 0 auto;">
              {t('Sign in')}
            </Button>{' '}
            <br />
          </StyledForm>
        </FormHolder>
      )}
      {forgetPassword && (
        <FormHolder>
          <Heading level={1}>Reset Password</Heading>
          <SubTitle>
            Enter your email so we can send you password reset email <br />
          </SubTitle>
          <StyledForm form={form} onSubmit={_ => onResetPassword(_, () => setForgetPassword(false))}>
            <Grid lg={24} xl={25}>
              <GridCol lg={24} xl={24}>
                <Form.Item
                  type="text"
                  label={t('Email')}
                  name="email"
                  placeholder={t('Your Email')}
                  prefix={<i className="material-icons-outlined">email</i>}
                  rules={[{ required: true }]}>
                  <Field />
                </Form.Item>
              </GridCol>
            </Grid>
            <Button loading={user_loading} type="primary" htmlType="submit" width={150} css="margin: 0 auto;">
              Reset Password
            </Button>
          </StyledForm>
          <div>
            <small>
              Already have an account?{' '}
              <a
                href="#"
                type="link"
                onClick={e => {
                  e.preventDefault();
                  setForgetPassword(false);
                }}>
                {t('Sign in')}
              </a>
            </small>
          </div>
        </FormHolder>
      )}
    </LoginTemplate>
  );
}

export default Login;
