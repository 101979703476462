import logo from 'assets/images/mst_controll.png';
import aresLogo from 'assets/images/ares logo 4.png';
import { LogoHolder, Img } from './Logo.styles';

function LogoComp() {
  const conditionAres = process.env.REACT_APP_BASE_API_URL.includes('ares');
  return (
    <LogoHolder>
      <Img src={logo} alt="admin" />
      {conditionAres && <Img src={aresLogo} alt="ares logo" />}
    </LogoHolder>
  );
}

export default LogoComp;
