import 'styled-components/macro';
import DataTabs from 'components/molecules/DataTabs';
import DetailsCard from 'components/molecules/DetailsCard';
import Grid from 'components/atoms/Grid';
import { useState } from 'react';
import InfoCard from 'components/molecules/InfoCard';
import Upload from 'components/molecules/Upload';
import { useContextHook } from 'use-context-hook';
import { format } from 'date-fns';
import apis from 'services/api';
import Toast from 'components/molecules/Toast';
import { usePermissions } from 'helpers/usePermissions';
import { getDateObject } from 'helpers/common';
import { AuthContext } from 'context/authContext';
import { useTranslation } from 'helpers/useTranslation';
import NoPermissions from 'pages/no-permissions';
import PasswordUpdateForm from './passwordUpdate';
import StoppageReasons from './StoppageReasons';
import ShiftsAndTimings from './ShiftsAndTimings';
import MachinesAndStdUnits from './MachinesAndStdUnits';
import Monitoring from './Monitring';
import Notifications from './Notifications';
import EmailAlerts from './EmailAlerts.jsx';

export default function Index() {
  const { hasPermission } = usePermissions();
  const { user } = useContextHook(AuthContext, {
    user: 1,
  });
  const { t } = useTranslation();
  const [logo, setLogo] = useState(user?.company_id?.company_logo);
  const content = [
    {
      label: t('Profile'),
      content: (
        <DetailsCard>
          <Grid xs={1} sm={2} gap={20}>
            <InfoCard title={t('First Name')} value={user?.first_name} $unStyled />
            <InfoCard title={t('Last Name')} value={user?.last_name} $unStyled />
            <InfoCard title={t('Company')} value={user?.company_id?.company_name} $unStyled />
            <InfoCard title={t('Email')} value={user.email} $unStyled />
            <InfoCard title={t('User Since')} value={format(getDateObject(user.created_at), 'dd MMM yyyy')} $unStyled />
            <InfoCard title={t('User Role')} value={user.role_type.join(',')} $unStyled />
          </Grid>
          <div
            css={`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            `}>
            <Upload
              value={logo}
              label={t('Company Logo')}
              name="company_logo"
              cropRatio={[1, 1]}
              base64
              onChange={e => {
                setLogo(e.target.value);
                apis
                  .updateCompanyDetails(user?.company_id?._id, {
                    ...user?.company_id,
                    company_logo: e.target.value,
                  })
                  .then(() => {
                    Toast({
                      type: 'success',
                      message: `Company logo updated successfully`,
                    });
                  })
                  .catch(() => {
                    Toast({
                      type: 'error',
                      message: `Company logo update failed`,
                    });
                  });
              }}
            />
          </div>
        </DetailsCard>
      ),
    },
    {
      label: t('Password'),
      content: hasPermission('profile.password_update.view') ? <PasswordUpdateForm /> : <NoPermissions />,
    },
    {
      label: t('Stoppage Reasons'),
      content: hasPermission('profile.stoppage_reasons.view') ? <StoppageReasons user={user} /> : <NoPermissions />,
    },
    {
      label: t('Shifts & Timings'),
      content: hasPermission('profile.shift_timings.view') ? <ShiftsAndTimings user={user} /> : <NoPermissions />,
    },
    {
      label: t('Machines & STD units'),
      content: hasPermission('profile.std.view') ? <MachinesAndStdUnits user={user} /> : <NoPermissions />,
    },
    {
      label: t('Monitoring'),
      content: hasPermission('profile.monitring.view') ? <Monitoring user={user} /> : <NoPermissions />,
    },
    {
      label: t('Notifications'),
      content: hasPermission('profile.notifications.view') ? <Notifications user={user} /> : <NoPermissions />,
    },
    {
      label: t('Email Alerts'),
      content: hasPermission('profile.alert.nav') ? <EmailAlerts user={user} /> : <NoPermissions />,
    },
  ];
  return <DataTabs data={content} />;
}
