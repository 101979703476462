/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';

import apis from 'services/api';

import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import GridCol from 'components/atoms/GridCol';
import { format } from 'date-fns';

function LogisticaFrom({ data, onClose = () => {} }) {
  const [form] = useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);

  useEffect(() => {
    if (data) {
      const _data = {
        fecha: data.fecha,
        cuadros_despachados: data.cuadros_despachados,
        comentarios: data.comentarios,
      };
      form.setFieldsValue({
        ..._data,
      });
      setState({
        ..._data,
      });
    }
  }, [data]);
  const onSubmit = async _data => {
    try {
      setLoading(true);
      if (data) {
        await apis.updateLogistica(data._id, {
          ..._data,
        });
      } else {
        await apis.createLogistica({
          ..._data,
        });
      }
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: 'Saved successfully',
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid
        xs={12}
        lg={12}
        colGap={10}
        css={`
          align-items: center;
        `}>
        <GridCol xs={12} md={12}>
          <Form.Item
            label="Fecha"
            name="fecha"
            placeholder="Fecha"
            prefix={<i className="material-icons-outlined">date_range</i>}
            rules={[
              {
                required: true,
              },
            ]}
            onChange={({ target: { value } }) => {
              form.setFieldsValue({
                fecha: format(value, 'yyyy-MM-dd'),
              });
            }}>
            <Field type="datepicker" />
          </Form.Item>
        </GridCol>
        <GridCol xs={12} md={12}>
          <Form.Item
            type="number"
            label="Cuadros despachados"
            name="cuadros_despachados"
            placeholder="Cuadros despachados"
            rules={[
              {
                required: true,
              },
            ]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol xs={12} md={12}>
          <Form.Item sm type="textarea" label="Comentarios" name="comentarios" placeholder="Comentarios">
            <Field />
          </Form.Item>
        </GridCol>
      </Grid>
      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        Save
      </Button>
    </Form>
  );
}

export default LogisticaFrom;
