import styled from 'styled-components/macro';

export const LogoHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  max-width: 150px;
  @media (min-width: 768px) {
    max-width: 150px;
  }
  @media (max-width: 768px) {
    max-width: 80px;
  }
`;
export const Img = styled.img``;
