import React, { useState } from 'react';
import Form, { useForm } from 'components/molecules/Form';
import Field from 'components/molecules/Field';
import Grid from 'components/atoms/Grid';
import GridCol from 'components/atoms/GridCol';
import Button from 'components/atoms/Button';
import apis from 'services/api';
import Toast from 'components/molecules/Toast';
import { useTranslation } from 'helpers/useTranslation';

function PasswordUpdateForm() {
  const [state, setState] = useState({});
  const [form] = useForm();
  const { t } = useTranslation();
  const handleSubmit = async () => {
    try {
      await apis.updatePassword({
        password: state.new_password,
        current_password: state.current_password,
      });
      Toast({
        type: 'success',
        message: 'Password updated successfully',
      });
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  return (
    <Form form={form} onSubmit={handleSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid
        xs={12}
        lg={12}
        colGap={10}
        css={`
          align-items: center;
          margin-top: 20px;
        `}>
        <GridCol xs={12} md={12}>
          <Form.Item
            name="current_password"
            label={t('current Password')}
            type="password"
            rules={[
              {
                required: true,
              },
              {
                password: true,
              },
            ]}>
            <Field />
          </Form.Item>
        </GridCol>
        <GridCol xs={12} md={12}>
          <Form.Item
            name="new_password"
            label={t('New Password')}
            type="password"
            rules={[
              {
                required: true,
              },
              {
                password: true,
              },
            ]}>
            <Field />
          </Form.Item>
        </GridCol>
      </Grid>
      <Button
        htmlType="submit"
        css={`
          margin-top: 20px;
          width: 100%;
        `}
        type="primary">
        {t('Update')}
      </Button>
    </Form>
  );
}

export default PasswordUpdateForm;
