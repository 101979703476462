/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import TableLayout from 'components/atoms/TableLayout';
import { Wrapper } from 'styles/App.styles';
import Table from 'components/molecules/Table';
import { ActionBtnHolder } from 'styles/helpers.styles';
import ModalContainer from 'components/molecules/ModalContainer';
import Button from 'components/atoms/Button';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import { usePermissions } from 'helpers/usePermissions';
import 'styled-components/macro';
import Toast from 'components/molecules/Toast';
import UploadCsv from 'components/organisms/CsvUpload';
import Form, { useForm } from 'components/molecules/Form';
import { useContextHook } from 'use-context-hook';
import { AuthContext } from 'context/authContext';
import apis from 'services/api';
import Alert from 'components/molecules/Alert';
import Field from 'components/molecules/Field';
import Select from 'components/atoms/Select';

const conditions = ['>', '<', '=', '>=', '<='].map(_ => ({ label: _, value: _ }));
const attributes = [
  'FIN_DE_TURNO',
  'FIN_DE_TURNO_MINS',
  'INCIO_DE_TURNO_MINS',
  'INICIO_DE_TURNO',
  'Contador_Piezas',
  'PZS_STD',
  'Parada_STD',
  'Paradas_Acumuladas',
  'TURNO',
  'Tiempo_de_Ciclo_real',
  'Tiempo_Calendario',
  'Tiempo_Disponible',
  'Tiempo_Posible',
  'Tiempo_Produccion',
  'Tiempo_STD_x_pieza',
  'Tiempo_efectivo',
  'Total_piezas',
  'Utilizacion_Disponible_Real',
  'Utilizacion_Disponible_STD',
  'Utilizacion_Posible_Real',
  'Utilizacion_Posible_STD',
].map(_ => ({ label: _, value: _ }));

function AddAlert({ update = false, onClose }) {
  const [form] = useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { user_options } = apis.GetUserOptions();
  const {
    reFetch,
    user: { company_id: company, email },
  } = useContextHook(AuthContext, {
    reFetch: 1,
    user: 1,
  });

  useEffect(() => {
    if (company) {
      let payload = {
        company_name: company.company_name.toLowerCase(),
        set_by: email,
      };
      if (update) {
        payload.company_name = update.company_name;
        payload.machines = update.machines.map(_ => ({ label: _, value: _ }));
        payload.emails = update.emails.map(_ => ({ label: _, value: _ }));
        payload.attribute = { value: update.attribute, label: update.attribute };
        payload.condition = { value: update.condition, label: update.condition };
        payload.value = update.value;
      }
      form.setFieldsValue(payload);
      setState(payload);
    }
  }, [company]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      if (!update) {
        await apis.addAlert({
          ...state,
          machines: state.machines.map(_ => _.value),
          emails: state.emails.map(_ => _.value),
          attribute: state.attribute.value,
          condition: state.condition.value,
        });
      } else {
        await apis.updateAlert(update._id, {
          ...state,
          machines: state.machines.map(_ => _.value),
          emails: state.emails.map(_ => _.value),
          attribute: state.attribute.value,
          condition: state.condition.value,
        });
      }
      Toast({
        type: 'success',
        message: `Alert added successfully`,
      });
      setLoading(false);
      onClose();
      reFetch();
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Form.Item
        type="text"
        label="EMPRESA"
        name="company_name"
        disabled
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="MACHINES"
        name="machines"
        isMulti
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}
        options={company?.std_units?.map(({ MACHINE }) => ({
          label: MACHINE,
          value: MACHINE,
        }))}>
        <Select />
      </Form.Item>
      <Form.Item
        type="text"
        label="FOR USERS"
        name="emails"
        options={user_options}
        isMulti
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Select />
      </Form.Item>
      <Form.Item
        type="text"
        label="attribute"
        name="attribute"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}
        options={attributes}>
        <Select />
      </Form.Item>
      <Form.Item
        type="text"
        label="Condition"
        name="condition"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}
        options={conditions}>
        <Select />
      </Form.Item>
      <Form.Item
        type="text"
        label="Value"
        name="value"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="Set By"
        name="set_by"
        disabled
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>

      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        Save
      </Button>
    </Form>
  );
}

export default function EmailAlerts({ user }) {
  const { hasPermission } = usePermissions();
  const {
    reFetch,
    fetch,
    user: { company_id: company },
  } = useContextHook(AuthContext, {
    reFetch: 1,
    fetch: 1,
    user: 1,
  });

  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 10,
    searchText: '',
    filterUsers: [],
    filterMachines: [],
  });

  const { alerts_data, alerts_loading } = apis.GetAlerts(searchQuery, fetch);

  const deleteRecord = async _ => {
    try {
      await apis.deleteAlert(_._id);
      Toast({
        type: 'success',
        message: `Record deleted successfully`,
      });
      reFetch();
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  const actionBtns = _ => (
    <ActionBtnHolder>
      {hasPermission('profile.alert.delete') && (
        <ConfirmationModal
          title="Are you sure you want to delete this record?"
          subtitle={"you can't undo this action"}
          deleteModal
          onOk={() => deleteRecord(_)}
          btnComponent={({ onClick }) => (
            <Button unStyled size={20} className="delete-btn" onClick={onClick}>
              <i className="material-icons-outlined">delete_forever</i>
            </Button>
          )}
        />
      )}
      {hasPermission('profile.alert.delete') && (
        <ModalContainer
          lg
          title="Edit STD unit"
          btnComponent={({ onClick }) => (
            <Button
              unStyled
              className="edit-btn"
              onClick={onClick}
              css={`
                color: green;
                font-size: 20px;
              `}>
              <i className="material-icons-outlined">edit</i>
            </Button>
          )}
          content={({ onClose }) => <AddAlert update={_} onClose={onClose} />}
        />
      )}
    </ActionBtnHolder>
  );

  const { rowsData, totalCount } = useMemo(
    () => ({
      rowsData: alerts_data?.alerts.map(_ => [
        _.created_at,
        _.set_by,
        _.machines.join(', '),
        _.emails.join(', '),
        `${_.attribute} ${_.condition} ${_.value}`,
        actionBtns(_),
      ]),
      totalCount: alerts_data?.totalItems,
    }),
    [alerts_data],
  );
  const columns = ['Created At', 'Created By', 'Machines', 'Users', 'Condition', 'Actions'];

  return (
    <Wrapper>
      <TableLayout
        customFilterKey="profile-emails"
        onChangeFilters={filters => {
          setSearchQuery(_ => ({
            ..._,
            ...filters,
          }));
        }}
        deleteAll={
          hasPermission('profile.alert.add') && (
            <>
              <ModalContainer
                lg
                title="Set up custom notifications"
                btnComponent={({ onClick }) => (
                  <Button
                    unStyled
                    className="edit-btn"
                    onClick={onClick}
                    css={`
                      color: green;
                      font-size: 20px;
                    `}>
                    <i className="material-icons-outlined">add_circle</i>
                  </Button>
                )}
                content={({ onClose }) => <AddAlert onClose={onClose} />}
              />
            </>
          )
        }
        currentPage={searchQuery.page}
        totalCount={totalCount}
        pageSize={searchQuery.pageSize}>
        <Table loading={alerts_loading} rowsData={rowsData} columnNames={columns} noPadding />
      </TableLayout>
    </Wrapper>
  );
}
