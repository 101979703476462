import styled, { css } from 'styled-components/macro';
import { RangePicker } from 'rc-picker';

import { styles } from '../Input/Input.styles';

export const StyledRangePicker = styled(RangePicker)`
  ${styles}
  padding-left: 15px;
  padding-right: 30px;
  background-color: var(--white);
  ${({ height }) => css`
    height: ${height};
  `}
  cursor: pointer;
  input {
    border: none;
    outline: none;
  }
`;
