/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useMemo, useState } from 'react';
import 'styled-components/macro';
import UploadFile from 'components/molecules/UploadFile';
import Toast from 'components/molecules/Toast';
import Button from 'components/atoms/Button';
import Table from 'components/molecules/Table';
import TableLayout from 'components/atoms/TableLayout';
import { ModalButtons } from './styles';

function UploadCsv({ onClose = () => {}, label, keys, onSubmit, showTable, ...rest }) {
  const [uploaded, setUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 5,
  });

  // Validate the CSV file against the provided keys
  const validateCSV = results => {
    const [csv_keys] = results;
    if (!csv_keys || !Array.isArray(results) || results.length === 0) {
      throw new Error('CSV file is empty.');
    }
    let missingKeys = [];
    missingKeys = keys.filter((key, i) => key.toLowerCase().trim() !== csv_keys[i].toLowerCase().trim());
    if (missingKeys.length > 0) {
      throw new Error(`CSV file is missing the following keys: ${missingKeys.join(', ')}`);
    }

    // You can add more validation logic if needed
  };

  const paginate = ({ rows: _rows, page, pageSize }) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const _ = _rows.slice(startIndex, endIndex);
    return _;
  };

  const handleOnError = _err => {
    onClose(_err.message);
    setUploaded(false);
    setRows([]);
  };

  const parseCSV = async results => {
    try {
      validateCSV(results.data);
      results.data.shift();
      if (!showTable) {
        onSubmit(
          results.data.map(x =>
            keys.reduce((obj, c, i) => {
              obj[c] = x[i];
              return obj;
            }, {}),
          ),
        );
      } else {
        setRows(results.data);
        setUploaded(true);
      }
    } catch (error) {
      handleOnError(error);
    }
  };

  const rowsData = useMemo(
    () =>
      paginate({ rows, ...searchQuery }).map(row => {
        const rowData = [];
        keys.forEach((key, index) => {
          rowData[index] = row[index] ?? `----`;
        });
        return rowData;
      }),
    [rows, searchQuery, keys],
  );

  const handleUpload = () => {
    setLoading(true);
    onSubmit(rows);
    setLoading(false);
    onClose();
    Toast({
      type: `success`,
      message: 'Uploaded Successfully',
    });
  };
  return !uploaded ? (
    <UploadFile {...rest} uploadBtnText={label} icon="file_upload" csv onUploadAccepted={parseCSV} />
  ) : (
    <>
      <TableLayout
        customFilterKey="upload_csv"
        onChangeFilters={filters => {
          setSearchQuery(prevQuery => ({ ...prevQuery, ...filters }));
        }}
        currentPage={searchQuery.page}
        totalCount={rows.length}
        pageSize={searchQuery.pageSize}>
        <Table width={1200} rowsData={rowsData} columnNames={keys} noPadding noOfLoadingRows={searchQuery.pageSize} />
      </TableLayout>
      <ModalButtons>
        <Button type="secondary" onClick={onClose} width={150}>
          Cancel
        </Button>
        <Button type="primary" width={150} onClick={handleUpload} loading={loading}>
          Upload
        </Button>
      </ModalButtons>
    </>
  );
}

export default UploadCsv;
