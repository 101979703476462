import React, { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import PhoneInput from 'components/atoms/PhoneInput';
import queryService from 'services/api';
import { useContextHook } from 'use-context-hook';

function ContactForm({ contact, onClose = () => {} }) {
  const [form] = useForm();
  const [state, setState] = useState({ valid: false });
  const [loading, setLoading] = useState(false);
  const { reFetch } = useContextHook(AuthContext, { reFetch: 1 });

  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        ...contact,
      });
      setState({
        ...contact,
      });
    }
  }, [contact]);
  const onSubmit = async data => {
    const updatedPayload = { ...data, ...state };
    const { valid, ...rest } = updatedPayload;
    try {
      setLoading(true);
      let response;
      if (contact) {
        response = await queryService.updateContact(contact._id, rest);
      } else {
        response = await queryService.createContact(rest);
      }
      reFetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: response.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  const handleOnChange = value => {
    setState(_ => ({ ..._, ...value }));
  };
  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="email"
          label="Email"
          name="email"
          placeholder="Email"
          rules={[
            { required: true, message: 'Please enter email address' },
            { email: true, message: 'Please enter a valid email' },
            { max: 40, message: 'Email should be at max 40 characters!' },
          ]}>
          <Field />
        </Form.Item>

        <PhoneInput
          value={state?.phone_number}
          required
          name="phone_number"
          label="Phone Number"
          onChange={handleOnChange}
        />

        <div />
      </Grid>

      <Button
        disabled={!state?.valid}
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        Save
      </Button>
    </Form>
  );
}

export default ContactForm;
