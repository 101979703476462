import { AuthContext } from 'context/authContext';
import { useContextHook } from 'use-context-hook';

export const usePermissions = () => {
  const { user } = useContextHook(AuthContext, {
    user: 1,
  });
  const hasPermission = perm => user?.permissions?.includes(perm);
  return { hasPermission };
};
