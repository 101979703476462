import React, { useMemo } from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import { useMediaPredicate } from 'react-media-hook';
import Header from 'components/molecules/Header';
import ModalContainer from 'components/molecules/ModalContainer';
import { useParams } from 'react-router-dom';
import { LoadingContext } from 'context/loadingContext';
import Skeleton from 'react-loading-skeleton';
import Button from 'components/atoms/Button';
import UserForm from 'components/organisms/UserForm';
import Select from 'components/atoms/Select';
import { useContextHook } from 'use-context-hook';
import apis from 'services/api';
import RolesForm from 'components/organisms/RolesForm';
import PermissionForm from 'components/organisms/PermissionsForm';
import Toast from 'components/molecules/Toast';
import CommentsForm from 'components/organisms/CommentsForm';
import EmbalajeForm from 'components/organisms/EmbalajeForm';
import { AuthContext } from 'context/authContext';
import EspecialesForm from 'components/organisms/EspecialesForm';
import UpdateCompanyInfo from 'components/organisms/UpdateCompanyInfo';
import Field from 'components/molecules/Field';
import CompanyForm from 'components/organisms/CompanyForm';
import { usePermissions } from 'helpers/usePermissions';
import LogisticaFrom from 'components/organisms/LogisticaForm';

import { useTranslation } from 'helpers/useTranslation';
import PageButtons from '../nav.json';
import ReportsSettings from 'components/organisms/ReportsSettings';
import ContactForm from 'components/organisms/ContactForm';
import EnquiryForm from 'components/organisms/EnquiryForm';

function TopBar({ title: pageTitle }) {
  // eslint-disable-next-line prefer-const
  let { view: title } = useParams();
  const { hasPermission } = usePermissions();
  const {
    dashboardInterval,
    setDashboardInterval,
    setCustomDateRangeForDashboard,
    customDateRangeForDashboard,
    lang,
    setLang,
  } = useContextHook(AuthContext, {
    dashboardInterval: 1,
    setDashboardInterval: 1,
    setCustomDateRangeForDashboard: 1,
    customDateRangeForDashboard: 1,
    lang: 1,
    setLang: 1,
  });
  const { t } = useTranslation();

  title = t(title.split('?')[0]);
  const { isLoading } = useContextHook(LoadingContext, {
    isLoading: 1,
  });

  const MinWidth1200 = useMediaPredicate('(min-width: 1200px)');
  const RangeWidth = useMediaPredicate('(min-width: 992px) and (max-width: 1199.98px)');
  const MaxWidth991 = useMediaPredicate('(max-width: 991px) ');
  function ButtonsSkeleton() {
    return (
      <>
        {MinWidth1200 && <Skeleton rectangle height={46} width={173} css="border-radius:75px !important;" />}
        {RangeWidth && <Skeleton circle height={46} width={46} />}
        {MaxWidth991 && <Skeleton circle height={18} width={18} />}
      </>
    );
  }

  const buttons = PageButtons.find(({ file }) => file === pageTitle)?.buttons ?? [];
  const handleChange = e => {
    setLang(e.target.value);
  };
  const restorePermissions = async () => {
    try {
      const res = await apis.restorePermissions();
      if (res) {
        Toast({
          type: 'success',
          message: 'Permissions restored successfully',
        });
      }
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex?.message,
      });
    }
  };
  const [startDate, endDate] = customDateRangeForDashboard;
  const optionsInterval = useMemo(
    () => [
      { value: 'day', label: t('Daily') },
      { value: 'week', label: t('Weekly') },
      { value: 'month', label: t('Monthly') },
      {
        value: '6month',
        label: t('6 Months'),
      },
      { value: 'year', label: t('Yearly') },
      { value: 'custom', label: t('Custom') },
    ],
    [lang.value],
  );
  return (
    <Header title={title.split('-').join(' ')}>
      {hasPermission('companies.update-company-info') && buttons.includes('update-company-info') && (
        <ModalContainer
          lg
          title={t('Update Company Info')}
          btnComponent={({ onClick }) => (
            <Button
              type="primary"
              onClick={onClick}
              iconMobile
              prefix={<i className="material-icons-outlined">edit</i>}>
              <span className="text">{t('Update Company Info')}</span>
            </Button>
          )}
          content={({ onClose }) => <UpdateCompanyInfo onClose={onClose} />}
        />
      )}
      {hasPermission('users.create-user') && buttons.includes('add-user') && (
        <ModalContainer
          title={t('Add User')}
          btnComponent={({ onClick }) =>
            isLoading ? (
              ButtonsSkeleton()
            ) : (
              <Button
                type="primary"
                sm
                rounded
                onClick={() => {
                  onClick();
                }}>
                <span className="text">{t('Add User')}</span>
              </Button>
            )
          }
          content={({ onClose }) => <UserForm onClose={onClose} />}
        />
      )}
      {buttons.includes('lang-select') && (
        <Select
          css={`
            width: 150px;
          `}
          label={t('Select Language')}
          options={[
            { value: 'en', label: 'English' },
            { value: 'sp', label: 'Español' },
          ]}
          value={lang}
          sm
          onChange={handleChange}
        />
      )}
      {buttons.includes('interval') && (
        <Select
          css={`
            width: 150px;
          `}
          sm
          lang={lang}
          label={t('Interval')}
          options={optionsInterval}
          value={dashboardInterval}
          onChange={({ target: { value } }) => setDashboardInterval(value)}
        />
      )}
      {buttons.includes('interval') && dashboardInterval.value === 'custom' && (
        <Field
          selectsRange
          startDate={startDate}
          endDate={endDate}
          onChange={({ target: { value } }) => {
            setCustomDateRangeForDashboard(value);
          }}
          prefix={<i className="material-icons-outlined">date_range</i>}
          placeholderText="Select date range"
          type="datepicker"
          label={t('Date Range')}
          noMargin
          sm
          clear={startDate || endDate}
        />
      )}
      {hasPermission('permissions.create') && buttons.includes('permissions.create') && (
        <ModalContainer
          lg
          title={t('Create Permission')}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{t('Create Permission')}</span>
            </Button>
          )}
          content={({ onClose }) => <PermissionForm onClose={onClose} />}
        />
      )}
      {hasPermission('permissions.restore') && buttons.includes('permissions.restore') && (
        <Button
          type="outline"
          onClick={restorePermissions}
          iconMobile
          prefix={<i className="material-icons-outlined">restore</i>}>
          <span className="text">{t('Restore Permissions')}</span>
        </Button>
      )}
      {hasPermission('roles.create') && buttons.includes('roles.create') && (
        <ModalContainer
          lg
          title={t('Create Role')}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{t('Create Role')}</span>
            </Button>
          )}
          content={({ onClose }) => <RolesForm onClose={onClose} />}
        />
      )}
      {hasPermission('comments.create') && buttons.includes('comments.create') && (
        <ModalContainer
          lg
          title={t('Create Comment')}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{t('Create Comment')}</span>
            </Button>
          )}
          content={({ onClose }) => <CommentsForm data={null} onClose={onClose} />}
        />
      )}
      {hasPermission('embalaje.create') && buttons.includes('embalaje.create') && (
        <ModalContainer
          lg
          title={t('Create Embalaje')}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{t('Create Embalaje')}</span>
            </Button>
          )}
          content={({ onClose }) => <EmbalajeForm data={null} onClose={onClose} />}
        />
      )}
      {hasPermission('logistica.create') && buttons.includes('logistica.create') && (
        <ModalContainer
          lg
          title={t('Create Logistics')}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{t('Create Logistics')}</span>
            </Button>
          )}
          content={({ onClose }) => <LogisticaFrom data={null} onClose={onClose} />}
        />
      )}
      {hasPermission('especiales.create') && buttons.includes('especiales.create') && (
        <ModalContainer
          lg
          title={t('Create Especiales')}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{t('Create Especiales')}</span>
            </Button>
          )}
          content={({ onClose }) => <EspecialesForm data={null} onClose={onClose} />}
        />
      )}
      {hasPermission('companies.create') && buttons.includes('companies.create') && (
        <ModalContainer
          lg
          title={t('Create Company')}
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">{t('Create Company')}</span>
            </Button>
          )}
          content={({ onClose }) => <CompanyForm onClose={onClose} />}
        />
      )}
      {buttons.includes('settings') && (
        <ModalContainer
          title={t('Settings')}
          btnComponent={({ onClick }) =>
            isLoading ? (
              ButtonsSkeleton()
            ) : (
              <Button type="primary" sm rounded onClick={onClick}>
                <span className="text">{t('Settings')}</span>
              </Button>
            )
          }
          content={({ onClose }) => <ReportsSettings onClose={onClose} />}
        />
      )}
      {buttons.includes('create-contact') && hasPermission('contact.create') && (
        <ModalContainer
          lg
          title="Create Contact"
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">Create Contact</span>
            </Button>
          )}
          content={({ onClose }) => <ContactForm onClose={onClose} />}
        />
      )}
      {buttons.includes('create-enquiry') && hasPermission('enquiry.create') && (
        <ModalContainer
          lg
          title="Create Enquiry"
          btnComponent={({ onClick }) => (
            <Button type="primary" onClick={onClick} iconMobile prefix={<i className="material-icons-outlined">add</i>}>
              <span className="text">Create Enquiry</span>
            </Button>
          )}
          content={({ onClose }) => <EnquiryForm onClose={onClose} />}
        />
      )}
    </Header>
  );
}

export default TopBar;
