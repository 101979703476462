import React, { useEffect, useMemo, useState } from 'react';
import TableLayout from 'components/atoms/TableLayout';
import { Wrapper } from 'styles/App.styles';
import Table from 'components/molecules/Table';
import { ActionBtnHolder } from 'styles/helpers.styles';
import ModalContainer from 'components/molecules/ModalContainer';
import Button from 'components/atoms/Button';
import ConfirmationModal from 'components/molecules/ConfirmationModal';
import { usePermissions } from 'helpers/usePermissions';
import 'styled-components/macro';
import Form, { useForm } from 'components/molecules/Form';
import { useContextHook } from 'use-context-hook';
import { AuthContext } from 'context/authContext';
import apis from 'services/api';
import Toast from 'components/molecules/Toast';
import Alert from 'components/molecules/Alert';
import UploadCsv from 'components/organisms/CsvUpload';
import Field from 'components/molecules/Field';

function AddStoppagesReasonsForm({ onClose }) {
  const [form] = useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    refetchUser,
    user: { company_id: company },
  } = useContextHook(AuthContext, {
    refetchUser: 1,
    user: 1,
  });
  useEffect(() => {
    if (company) {
      form.setFieldsValue({
        ...company,
      });
      setState({
        ...company,
      });
    }
  }, [company]);
  const onSubmit = async () => {
    try {
      setLoading(true);
      await apis.updateCompanyDetails(state._id, state);
      Toast({
        type: 'success',
        message: `Company data updated successfully`,
      });
      setLoading(false);
      onClose();
      refetchUser();
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  return (
    <>
      <Alert
        type="info"
        css={`
          margin-bottom: 20px;
        `}
        message={
          <small>
            Please upload csv file with the following columns in same sequence: EMPRESA, CAUSA, DESCRIPCION, Tipo de
            TIEMPO - AGO, Tipo de PARADA.
          </small>
        }
      />
      <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
        <Form.Item
          type="text"
          label="Select Reasons Csv"
          name="stoppage_reasons"
          prefix={<i className="material-icons-outlined">person</i>}
          rules={[{ required: true }]}>
          <UploadCsv
            onClose={e => {
              if (typeof e === 'string')
                Toast({
                  type: 'error',
                  message: e,
                });
              form.setFieldsValue({
                stoppage_reasons: '',
              });
            }}
            keys={['EMPRESA', 'CAUSA', 'DESCRIPCION', 'Tipo de TIEMPO - AGO', 'Tipo de PARADA']}
            onSubmit={e => {
              if (e?.length) {
                form.setFieldsValue({
                  stoppage_reasons: e,
                });
              } else {
                form.setFieldsValue({
                  stoppage_reasons: '',
                });
              }
            }}
          />
        </Form.Item>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          css={`
            margin-top: 20px;
          `}>
          Save
        </Button>
      </Form>
    </>
  );
}

function UpdateStoppageReasonForm({ stoppageReason, onClose }) {
  const [form] = useForm();
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    refetchUser,
    user: { company_id: company },
  } = useContextHook(AuthContext, {
    refetchUser: 1,
    user: 1,
  });
  useEffect(() => {
    if (company) {
      form.setFieldsValue({
        ...stoppageReason,
      });
      setState({
        ...stoppageReason,
      });
    }
  }, [company]);
  const onSubmit = async () => {
    try {
      setLoading(true);
      await apis.updateCompanyDetails(company._id, {
        ...company,
        stoppage_reasons: company.stoppage_reasons.map(_ => (_.CAUSA === stoppageReason.CAUSA ? state : _)),
      });
      Toast({
        type: 'success',
        message: `Company data updated successfully`,
      });
      setLoading(false);
      onClose();
      refetchUser();
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };
  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Form.Item
        type="text"
        label="EMPRESA"
        name="EMPRESA"
        disabled
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="CAUSA"
        name="CAUSA"
        disabled
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="DESCRIPCION"
        name="DESCRIPCION"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="Tipo de PARADA"
        name="Tipo de PARADA"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Form.Item
        type="text"
        label="Tipo de TIEMPO - AGO"
        name="Tipo de TIEMPO - AGO"
        prefix={<i className="material-icons-outlined">person</i>}
        rules={[{ required: true }]}>
        <Field />
      </Form.Item>
      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        Save
      </Button>
    </Form>
  );
}

export default function StoppageReasons({ user }) {
  const { hasPermission } = usePermissions();
  const {
    refetchUser,
    user: { company_id: company },
  } = useContextHook(AuthContext, {
    refetchUser: 1,
    user: 1,
  });
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 10,
    searchText: '',
    totalCount: user?.company_id?.stoppage_reasons?.length,
  });

  const [rows, setRows] = useState([]);

  const paginate = ({ rows: _rows, page, pageSize, searchText }) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const filteredRows = _rows.filter(_ => {
      const regex = new RegExp(searchText, 'i');
      return regex.test(_.DESCRIPCION) || regex.test(_['Tipo de PARADA']) || regex.test(_['Tipo de TIEMPO - AGO']);
    });
    setSearchQuery({ ...searchQuery, totalCount: filteredRows.length });
    return filteredRows.slice(startIndex, endIndex);
  };

  useEffect(() => {
    setRows(
      paginate({
        rows: user?.company_id?.stoppage_reasons,
        ...searchQuery,
      }),
    );
  }, [searchQuery.page, searchQuery.pageSize, searchQuery.searchText]);

  const deleteRecord = async _ => {
    try {
      await apis.updateCompanyDetails(company._id, {
        ...company,
        stoppage_reasons: company.stoppage_reasons.filter(__ => __.CAUSA !== _.CAUSA),
      });
      Toast({
        type: 'success',
        message: `Company data updated successfully`,
      });
      refetchUser();
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  const actionBtns = _ => (
    <ActionBtnHolder>
      {hasPermission('profile.stoppage_reasons.edit') && (
        <ModalContainer
          lg
          title="Edit Especiales"
          btnComponent={({ onClick }) => (
            <Button unStyled className="edit-btn" onClick={onClick}>
              <i className="material-icons-outlined">edit</i>
            </Button>
          )}
          content={({ onClose }) => <UpdateStoppageReasonForm stoppageReason={_} onClose={onClose} />}
        />
      )}
      {hasPermission('profile.stoppage_reasons.delete') && (
        <ConfirmationModal
          title="Are you sure you want to delete this record?"
          subtitle={"you can't undo this action"}
          deleteModal
          onOk={() => deleteRecord(_)}
          btnComponent={({ onClick }) => (
            <Button unStyled size={20} className="delete-btn" onClick={onClick}>
              <i className="material-icons-outlined">delete_forever</i>
            </Button>
          )}
        />
      )}
    </ActionBtnHolder>
  );

  const rowsData = useMemo(
    () => rows.map(_ => [_.CAUSA, _.DESCRIPCION, _['Tipo de PARADA'], _['Tipo de TIEMPO - AGO'], actionBtns(_)]),
    [rows],
  );

  const columns = ['CAUSA', 'DESCRIPCION', 'Tipo de PARADA', 'Tipo de TIEMPO - AGO', ''];

  return (
    <Wrapper>
      <TableLayout
        customFilterKey="text-only"
        onChangeFilters={filters => {
          setSearchQuery(_ => ({
            ..._,
            ...filters,
          }));
        }}
        deleteAll={
          hasPermission('profile.stoppage_reasons.upload') && (
            <ModalContainer
              title="Upload Stoppages Reasons CSV"
              btnComponent={({ onClick }) => (
                <Button
                  unStyled
                  css={`
                    color: green;
                    font-size: 20px;
                  `}
                  onClick={onClick}>
                  <i className="material-icons-outlined">add_circle</i>
                </Button>
              )}
              content={({ onClose }) => <AddStoppagesReasonsForm onClose={onClose} />}
            />
          )
        }
        currentPage={searchQuery.page}
        totalCount={searchQuery.totalCount}
        pageSize={searchQuery.pageSize}>
        <Table loading={false} rowsData={rowsData} columnNames={columns} noPadding />
      </TableLayout>
    </Wrapper>
  );
}
